<template>
  <a-modal
    :visible="showModal"
    centered
    destroyOnClose
    title="Select Page Numbers"
    okText="Download"
    :okButtonProps="{ disabled: !isFastLabelAvailable }"
    id="download-task-video-modal"
    @cancel="clearSelectedPages"
    @ok="downloadTaskVideos"
  >
    <template #closeIcon>
      <close-outlined />
    </template>

    <a-select
      :disabled="!startPages.length > 0"
      :key="startPages"
      show-search
      :filter-option="null"
      v-model:value="selectedStartPage"
      :options="startPages"
      class="w-100 mb-4"
      placeholder="Select Start page"
    ></a-select>

    <a-select
      :disabled="!endPages.length > 0"
      :key="endPages"
      show-search
      :filter-option="null"
      v-model:value="selectedEndPage"
      :options="endPages"
      class="w-100"
      placeholder="Select End page"
    ></a-select>
  </a-modal>

  <div class="content-i">
    <div class="content-box">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xxxl-12">
          <div class="element-wrapper">
            <h6 class="element-header">Tasks</h6>
            <div
              v-bind:class="getStyle('availableLocally')"
              style="margin-bottom: 20px"
            >
              <!--              <i v-bind:class="getClass('availableLocally')"></i>-->
              <!--              {{-->
              <!--                isFastLabelAvailable-->
              <!--                  ? 'Fast Labelling Available'-->
              <!--                  : 'Fast Labelling unavailable'-->
              <!--              }}-->
            </div>
            <a-table
              :data-source="taskList"
              :columns="columns"
              :scroll="{
                y: '55vh',
              }"
              :pagination="false"
              class="w-100"
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.dataIndex === 'actions'">
                  <a-button
                    type="primary"
                    class="mr-2"
                    @click="goToVideos(record)"
                  >
                    Show Videos
                  </a-button>
                  <a-button
                    type="primary"
                    @click="openModal(record)"
                    :disabled="!isFastLabelAvailable"
                  >
                    Download Videos
                  </a-button>
                </template>
              </template>
            </a-table>
          </div>
        </div>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import httpClient from '../../../../service/httpClient.js';
import { useToast } from 'vue-toastification';
// import Multiselect from '@vueform/multiselect';
import { CloseOutlined } from '@ant-design/icons-vue';

export default {
  name: 'labeler-task-component',
  components: {
    // Multiselect,
    CloseOutlined,
  },
  data() {
    return {
      taskList: [],
      isFastLabelAvailable: false,
      polling: null,
      pollingTime: 2000,
      isPolled: false,
      Task__id: null,
      Task__taskName: null,
      Task__organization: null,
      selectedStartPage: null,
      selectedEndPage: null,
      endPages: [],
      startPages: [],
      pages: [],
      pageSize: 10,
      showModal: false,
    };
  },

  async created() {
    window.addEventListener('beforeunload', this.resetPolling);
    await this.isFastLabellingAvailable();
  },
  async mounted() {
    await this.getAssignedTasks();
    this.startPolling();
  },
  setup() {
    const toast = useToast();
    const columns = [
      {
        title: 'Task Id',
        dataIndex: 'Task__id',
        key: 'task_id',
        align: 'center',
      },
      {
        title: 'Task Name',
        dataIndex: 'Task__taskName',
        key: 'taskName',
        align: 'center',
      },
      {
        title: 'Number of Videos',
        dataIndex: 'numVideos',
        key: 'numVideos',
        align: 'center',
      },
      {
        title: 'Number of Labels',
        dataIndex: 'numLabelled',
        key: 'numLabelled',
        align: 'center',
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        align: 'left',
        width: '300px',
      },
    ];
    return { toast, columns };
  },
  methods: {
    getArrayOfConsecutiveNumbers: function (pageCount) {
      return Array.from({ length: pageCount }, (_, i) => ({
        label: i + 1,
        value: i + 1,
      }));
    },
    setPages: function (numVideos) {
      const pageCount = Math.ceil(numVideos / this.pageSize);
      this.startPages = this.getArrayOfConsecutiveNumbers(pageCount);
      this.endPages = this.getArrayOfConsecutiveNumbers(pageCount);
      this.pages = this.getArrayOfConsecutiveNumbers(pageCount);
    },
    setupTaskParams: function (Task__id, Task__taskName, organization) {
      this.Task__id = Task__id;
      this.Task__taskName = Task__taskName;
      this.Task__organization = organization;
    },
    openModal({ Task__id, Task__taskName, organization, numVideos }) {
      this.setupTaskParams(Task__id, Task__taskName, organization);
      this.setPages(numVideos);
      this.showModal = true;
    },
    startPolling() {
      this.polling = setInterval(
        this.isFastLabellingAvailable,
        this.pollingTime
      );
    },
    resetPolling() {
      clearInterval(this.polling);
    },
    getClass(property) {
      return {
        dotgreen: this.isFastLabelAvailable,
        dotgrey: !this.isFastLabelAvailable,
      };
    },
    getStyle(property) {
      return {
        active: this.isFastLabelAvailable,
        inactive: !this.isFastLabelAvailable,
      };
    },
    async getAssignedTasks() {
      const labelerId = localStorage.getItem('id');
      const url = `cloud_source_labeling/assigned_task/${labelerId}`;
      const res = await httpClient.get(url);
      if (res.length === 0) {
        console.log('no task is assigned!');
        return;
      }
      const urlForTaskProgress = `organization/task/labelling_progess?tasks=${res
        .map((e) => e.id)
        .join()}`;
      const tasksProgess = await httpClient.get(urlForTaskProgress);
      this.taskList = tasksProgess;
    },

    goToVideos({ Task__id, Task__taskName, organization }) {
      let videosRoute = this.$router.resolve({
        name: 'Videos List',
        params: {
          taskId: Task__id,
          taskName: Task__taskName,
          organization: organization,
        },
      });
      window.open(videosRoute.href, '_blank');
    },
    clearSelectedPages: function () {
      this.selectedStartPage = null;
      this.selectedEndPage = null;
      this.showModal = false;
    },
    setupSelectedPages: function () {
      if (!this.selectedEndPage) {
        this.selectedEndPage = this.selectedStartPage;
      }
      if (!this.selectedStartPage) {
        this.selectedStartPage = this.selectedEndPage;
      }
    },
    async downloadTaskVideos() {
      if (!this.selectedEndPage && !this.selectedStartPage) {
        this.toast.error('Please select start or end page first!');
        return;
      }
      this.setupSelectedPages();
      if (this.selectedStartPage > this.selectedEndPage) {
        this.toast.error('End page cannot lie before start page!');
        return;
      }
      const baseURL =
        '/download/' +
        this.Task__organization +
        '/' +
        this.Task__id +
        '/' +
        this.Task__taskName +
        '/' +
        String(this.selectedStartPage) +
        '/' +
        String(this.selectedEndPage);
      const res = await httpClient.get(
        baseURL,
        false,
        true,
        false,
        'successful',
        'failed',
        'http://localhost:5000/'
      );
      if (res === 403) {
        let prevTask = localStorage.getItem('downloadTaskVideos');
        this.toast.error(
          prevTask +
            ' videos download is already in progress. Please try again later.'
        );
        retrun;
      }
      localStorage.setItem('downloadTaskVideos', this.Task__taskName);
      this.showModal = false;
      this.clearSelectedPages();
      this.toast.success(this.Task__taskName + ' videos download has started!');
    },
    async isFastLabellingAvailable() {
      if (this.isPolled) return;
      this.isPolled = true;

      const res = await httpClient.get(
        '/active',
        false,
        true,
        false,
        'successful',
        'failed',
        'http://localhost:5000/'
      );
      if (res === 'error') {
        this.isFastLabelAvailable = false;
        // this.toast.info("Fast labelling Available");
      } else {
        this.isFastLabelAvailable = true;
      }
      this.isPolled = false;
    },
  },
  beforeUnmount() {
    this.resetPolling();
    window.removeEventListener('beforeunload', this.resetPolling);
  },
};
</script>

<style>
table,
tr,
td {
  text-align: left;
  margin-right: auto;
  margin-left: 0px;
}
</style>
